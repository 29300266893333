<template>
  <v-container>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t('dialogMessages.itemDeleteConfirmation')
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t('buttons.cancel')
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
            $t('buttons.ok')
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn class="success" @click="downloadCsv">{{
      $t('buttons.exportClaims')
    }}</v-btn>
    <v-row no-gutters>
      <v-col cols="3">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          v-model="searchString"
          placeholder="Search"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="10" md="8">
        <v-chip-group
          v-model="filters.selection"
          multiple
          active-class="primary--text"
        >
          <v-chip v-if="!isClient" value="Followed">
            {{ $t('filters.followed') }}
          </v-chip>
          <v-chip value="Open"> {{ $t('filters.open') }} </v-chip>
          <v-chip value="Closed"> {{ $t('filters.closed') }} </v-chip>
          <v-chip v-if="!isClient" value="New">
            {{ $t('filters.new') }}
          </v-chip>
          <v-chip value="2023"> 2023 </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-autocomplete
          dense
          v-if="clients"
          :items="clientSelectItems"
          v-model="filters.client"
          :label="$t('filters.client')"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-menu
          v-model="incidentDateFromDatepicker_show"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="filters.incidentDateFromDate"
              :label="$t('filters.incidentDateFrom')"
              v-on="on"
              clearable
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.incidentDateFromDate"
            @input="incidentDateFromDatepicker_show = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu
          v-model="incidentDateToDatepicker_show"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="filters.incidentDateToDate"
              :label="$t('filters.incidentDateTo')"
              v-on="on"
              clearable
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.incidentDateToDate"
            @input="incidentDateToDatepicker_show = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table
      :headers="_headersWithActions"
      :items="sortedClaimsWithDuration"
      :item-class="classChecker"
      :loading="isLoading"
      :header-props="headerProps"
      :items-per-page="-1"
      class="elevation-1"
    >
      <template v-slot:item.id="{ item }">
        <router-link :to="`/krdna/${item.id}/`">
          <span>{{
            item.claimNumber ? item.claimNumber : item.id
          }}</span></router-link
        >
      </template>
      <template v-slot:item.incidentLocation="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ truncate(item.incidentLocation, 8) }}
            </span>
          </template>
          <span>
            {{ item.incidentLocation }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.circumstance.name="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ item.circumstance ? truncate(item.circumstance.name, 8) : '' }}
            </span>
          </template>
          <span>
            {{ item.circumstance ? item.circumstance.name : '' }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.incidentDate="{ item }">
        <span>
          {{
            item.incidentDate == '0001-01-01T00:00:00Z'
              ? ''
              : formatDate(item.incidentDate)
          }}
        </span>
      </template>
      <template v-if="!isClient" v-slot:item.outcome="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ truncate(item.outcome, 8) }}
            </span>
          </template>
          <span>
            {{ item.outcome }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.stage.displayName="{ item }">
        <span :class="stageColor(item.stage.displayName)">
          {{ $t(`stages["${item.stage.displayName}"]`) }}
        </span>
      </template>
      <template v-slot:item.stageChangeDate="{ item }">
        <span>
          {{ item.stageChangeDate ? formatDate(item.stageChangeDate) : '' }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon> -->
        <v-icon v-if="isAdmin" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import { exportCsv } from './../utils/KRDNAExportCSV'
import {
  removeUnwantedSeparators,
  getTimeDifferenceInDays,
  getTimeDifferenceInDaysUntilNow,
} from './../utils/formattingHelpers'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      isLoading: true,
      incidentDateFromDatepicker_show: false,
      incidentDateToDatepicker_show: false,
      dialogDelete: false,
      dialog: false,
      editedIndex: -1,
      editedItem: {},
      hideHeadersFromClients: ['outcome', 'duration'],
      filters: {
        selection: [],
        client: '',
        incidentDateFromDate: '',
        incidentDateToDate: '',
      },
      searchString: '',
    }
  },
  //TODO: Search,filters, export
  created() {
    this.$store.dispatch('client/getClients')
    this.$store
      .dispatch('mtpl/getClaims', { pipelineId: 5, orderBy: 'reportedDate' })
      .then(() => {
        this.isLoading = false
      })
    // TODO: Fix all filters for other types <MTPLFilters> search
    // if (sessionStorage.getItem('MTPLFilters')) {
    //   let JSONFilters = sessionStorage.getItem('MTPLFilters')
    //   let filters = JSON.parse(JSONFilters)
    //   // TODO: Implement chip active change.
    //   this.$nextTick(() => {
    //     this.filters = Object.assign({}, this.filters, filters)
    //   })
    // }
  },
  mounted() {
    if (this.isClient) {
      this.filters.selection = ['Open']
    } else {
      this.filters.selection = ['Followed']
    }
    if (!this.isClient) {
      this.$store.dispatch('stage/getStages', 5)
    }
    if (!this.isClient && localStorage.getItem('MTPLInjuredFilters')) {
      let JSONFilters = localStorage.getItem('MTPLInjuredFilters')
      let filters = JSON.parse(JSONFilters)
      this.$nextTick(() => {
        this.filters = Object.assign({}, this.filters, filters)
      })
    }
  },
  beforeDestroy() {
    this.$store.dispatch('mtpl/clearClaims')
    localStorage.setItem('MTPLInjuredFilters', JSON.stringify(this.filters))
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      mtpls: (state) => state.mtpl.mtpls,
      user: (state) => state.user.user,
      clients: (state) => state.client.clients,
    }),

    headers() {
      return [
        {
          text: this.$i18n.t('headers.claim'),
          value: 'id',
        },
        {
          text: this.$i18n.t('headers.stage'),
          value: 'stage.displayName',
        },
        {
          text: this.$i18n.t('headers.stageChangeDate'),
          value: 'stageChangeDate',
        },
        {
          text: this.$i18n.t('headers.client'),
          value: 'client.name',
        },
        {
          text: this.$i18n.t('headers.krdClaim'),
          value: 'KRDInsuranceClaimNumber',
        },
        {
          text: this.$i18n.t('headers.naClaim'),
          value: 'NAInsuranceClaimNumber',
        },
        {
          text: this.$i18n.t('headers.insuredPerson'),
          value: 'insuredPerson.name',
        },
        {
          text: this.$i18n.t('headers.incidentDate'),
          value: 'incidentDate',
        },
        {
          text: this.$i18n.t('headers.location'),
          value: 'incidentLocation',
        },
        {
          text: this.$i18n.t('headers.outcome'),
          value: 'outcome',
        },
        {
          text: this.$i18n.t('headers.duration'),
          value: 'duration',
        },
      ]
    },

    headersWithActions() {
      return [
        ...this.headers,
        {
          text: this.$i18n.t('headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    _headersWithActions() {
      return this.headersWithActions.filter(
        (h) => !this.hideHeadersFromClients.includes(h.value) || !this.isClient
      )
    },
    sortedClaims() {
      if (this.filteredClaims) {
        return this.filteredClaims.concat().sort(this.sortClaimsDefault)
      } else {
        return this.filteredClaims
      }
    },
    sortedClaimsWithDuration() {
      return this.sortedClaims.map((c) => {
        return {
          ...c,
          duration: c.processDuration
            ? getTimeDifferenceInDays(c.reportedDate, c.processDuration)
            : getTimeDifferenceInDaysUntilNow(c.reportedDate),
        }
      })
    },
    filteredClaims() {
      let conditions = []

      this.filters.selection.includes('Open') &&
        conditions.push(this.filterOpenClaims)
      this.filters.selection.includes('Closed') &&
        conditions.push(this.filterClosedClaims)
      this.filters.selection.includes('Followed') &&
        conditions.push(this.filterFollowedClaims)
      this.filters.selection.includes('2023') &&
        conditions.push(this.filterCurrentYearClaims)
      this.filters.selection.includes('New') &&
        conditions.push(this.filterNewClientClaims)

      if (this.searchString !== '' && this.searchString != null) {
        conditions.push(this.filterSearch)
      }

      if (this.filters.client !== '' && this.filters.client != null) {
        conditions.push(this.filterClaimClient)
      }

      if (
        this.filters.incidentDateFromDate !== '' &&
        this.filters.incidentDateFromDate != null
      ) {
        conditions.push(this.filterClaimFromDate)
      }

      if (
        this.filters.incidentDateToDate !== '' &&
        this.filters.incidentDateToDate != null
      ) {
        conditions.push(this.filterClaimToDate)
      }

      if (conditions.length > 0 && this.mtpls.claims) {
        return this.mtpls.claims.filter((claim) => {
          return conditions.every((condition) => {
            return condition(claim)
          })
        })
      }

      return this.mtpls.claims
    },
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
    isAdmin() {
      return this.$store.getters['user/role'] == 'admin'
    },
    headerProps() {
      return {
        sortByText: this.$i18n.t(`dataTable.sortBy`),
      }
    },
    clientSelectItems() {
      return this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
  },
  methods: {
    classChecker(item) {
      if (item.createdByClient) {
        return 'custom-bg'
      } else {
        return ''
      }
    },
    filterFollowedClaims(item) {
      return item.followers.map((f) => f.id).includes(this.user.id)
    },
    filterClosedClaims(item) {
      return item.stage.isFinal
    },
    filterOpenClaims(item) {
      return !item.stage.isFinal
    },
    filterCurrentYearClaims(item) {
      const yearToCheck = 2023
      return dayjs(item.reportedDate).isSame(`${yearToCheck}`, 'year')
    },
    filterNewClientClaims(item) {
      return (
        item.createdByClient &&
        (item.insuranceClaimNumber == null || item.insuranceClaimNumber == '')
      )
    },
    getCurrentTimeString() {
      let timestamp = new Date()
      return (
        '' +
        timestamp.getFullYear() +
        timestamp.getMonth() +
        timestamp.getDay() +
        timestamp.getHours() +
        timestamp.getMinutes() +
        timestamp.getSeconds()
      )
    },
    filterClaimClient(item) {
      return item.client.id == this.filters.client
    },
    filterSearch(item) {
      return (
        item.client?.name
          .toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.id?.toString().includes(this.searchString) ||
        item.insuranceClaimNumber?.toString().includes(this.searchString) ||
        item.KRDInsuranceClaimNumber?.toString().includes(this.searchString) ||
        item.NAInsuranceClaimNumber?.toString().includes(this.searchString) ||
        item.claimNumber
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.trailerRegistration
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.vehicle?.registration
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.stage?.displayName
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        this.formatDate(item.incidentDate).includes(this.searchString) ||
        item.location?.includes(this.searchString) ||
        item.insuranceProvider?.includes(this.searchString) ||
        item.incidentLocation
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.circumstance?.name
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.outcome?.toString().includes(this.searchString) ||
        (item.stage?.displayName &&
          this.$i18n
            .t(`stages["${item.stage?.displayName}"]`)
            .toLowerCase()
            .includes(this.searchString.toLowerCase()))
      )
      /*
       */
    },
    filterClaimFromDate(item) {
      let fromDate = dayjs(this.filters.incidentDateFromDate)
      let createdDate = dayjs(item.incidentDate)
      return createdDate.diff(fromDate) >= 0
    },
    filterClaimToDate(item) {
      let toDate = dayjs(this.filters.incidentDateToDate)
      let createdDate = dayjs(item.incidentDate)
      return toDate.diff(createdDate) >= 0
    },
    downloadCsv() {
      let dataToExport = this.sortedClaimsWithDuration.map((c) => ({
        stage: this.nullCheck(c.stage.displayName),
        stageChangeDate: c.stageChangeDate
          ? this.formatDate(c.stageChangeDate)
          : '',
        client: this.nullCheck(c.client.name),
        KRDClaim: c.KRDInsuranceClaimNumber ? c.KRDInsuranceClaimNumber : '',
        KRDInsuranceProvider: c.KRDInsuranceProvider
          ? c.KRDInsuranceProvider
          : '',
        NAClaim: c.NAInsuranceClaimNumber ? c.NAInsuranceClaimNumber : '',
        NAInsuranceProvider: c.NAInsuranceProvider ? c.NAInsuranceProvider : '',
        insuredPerson: c.insuredPerson.name,
        birthDate: c.insuredPerson?.birthDate
          ? this.formatDate(c.insuredPerson.birthDate)
          : '',
        beneficiaryIBAN: this.nullCheck(c.beneficiaryIBAN),
        expenses: c.expenses ? this.formatAmount(c.expenses) : '',
        payoutReceived: c.payoutReceived
          ? this.formatAmount(c.payoutReceived)
          : '',
        incidentDate:
          c.incidentDate == '0001-01-01T00:00:00Z'
            ? ''
            : this.formatDate(this.nullCheck(c.incidentDate)),
        location: this.nullCheck(c.incidentLocation),
        hospitalContacts: removeUnwantedSeparators(
          this.nullCheck(c.hospitalContacts)
        ),
        outcome: removeUnwantedSeparators(this.nullCheck(c.outcome)),
        duration: c.duration,
      }))

      // exportCsv(dataToExport)
      const link = document.createElement('a')
      link.setAttribute('href', exportCsv(dataToExport))
      link.setAttribute(
        'download',
        `claimExport ${this.getCurrentTimeString()}.csv`
      )
      link.click()
    },

    nullCheck(value) {
      return value ? value : ''
    },
    truncate(input, length = 5) {
      if (input != null) {
        return input.length > length
          ? `${input.substring(0, length)}...`
          : input
      }
      return input
    },

    isEmpty(value) {
      if (value != null && value.length > 0) {
        return 'Y'
      }
      return 'N'
    },
    sortClaimsDefault(a, b) {
      let aCondition = a.createdByClient && a.insuranceClaimNumber === ''
      let bCondition = b.createdByClient && b.insuranceClaimNumber === ''
      if (Number(aCondition) < Number(bCondition)) {
        return 1
      }
      if (Number(aCondition) > Number(bCondition)) {
        return -1
      }
      return 0
    },
    formatAmount(amount) {
      if (amount > 0) {
        return amount / 100
      } else {
        return amount
      }
    },
    stageColor(displayName) {
      switch (displayName) {
        case 'Missing contact details':
          return 'red--text'
        case 'Large claim':
          return 'red--text'
        case 'Reported by insurer':
          return 'red--text'
        case 'Agreed, but unpaid':
          return 'orange--text'
        case 'Paid/Closed':
          return 'green--text'
        default:
          return ''
      }
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
    deleteItem(item) {
      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$store.dispatch('mtpl/deleteClaim', this.editedIndex)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    editItem(item) {
      this.editedIndex = this.mtpls.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
  },
}
</script>

<style lang="scss">
.custom-bg {
  background-color: #f7ebe3;
}
</style>
